//@ts-check
import observe from '../utils/observe.js';
import {CLS_FACTOR} from '../utils/constants.js';
import {round, disconnectHandler, closestId} from '../utils/utils.js';

const entryType = 'layout-shift';

/**
 * Get CLS attributes
 * @param {import('../utils/utils.js').State} state
 */
export default function cls([, , PerformanceObserver]) {
    const observer = observe(PerformanceObserver, entryType);
    if (!observer) {
        return;
    }
    const entries = observer.takeRecords?.();
    disconnectHandler(observer)();
    if (!entries) {
        return;
    }

    const [cls, countCls, map] = /** @type {Array<any>} */(entries)
        .filter(({hadRecentInput}) => !hadRecentInput)
        .map(({sources, value}) => {
            const {node} = sources.reduce((acc, {node, currentRect: {width, height}}) => {
                const area = width * height;
                if (area < acc.area) {
                    return acc;
                }
                return {
                    area,
                    node
                };
            }, {area: -1, node: null});
            return [
                node,
                value
            ];
        })
        .reduce(([cls, countCls, map], [node, value]) => {
            if (node) {
                map.set(node, value + (map.get(node) || 0));
            }
            return [
                cls + value,
                ++countCls,
                map   
            ];
        }, [0, 0, new Map()]);

    const nodes = [];
    for (const entry of map.entries()) {
        nodes.push(entry);
    }
    nodes.sort((a, b) => b[1] - a[1]);

    const result = {
        cls: round(cls * CLS_FACTOR),
        countCls
    };
    if (nodes.length) {
        let node = nodes[0][0];
        if (node.nodeType !== Node.ELEMENT_NODE) {
            node = node.parentElement;
        }
        const cid = closestId(node);
        if (cid) {
            result.clsId = cid;
        }
        const {tagName} = node;
        if (tagName) {
            result.clsTag = tagName;
        }
    }
    return result;
}
